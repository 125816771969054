$primary: #ca3900;
$secondary: #e9632f;
$white: #f3f3f3;
$gray: #9b9b9b;

input {
  font-family: 'Noto Sans KR', sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.2rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  text-align: center;
  padding: 0px
}

input:focus {
  padding-bottom: 6px;  
  font-weight: 600;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary,$secondary);
  border-image-slice: 1;
}
/* reset input */
input{
  &:required,&:invalid { box-shadow:none; }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Input {
    font-family: 'Noto Sans KR', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#282c34;
    margin: auto 4px;
    width: 100px;
  }