.AgregarConstructor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Formulario {
    font-family: 'Noto Sans KR', sans-serif;
    width: 40%;
    height: auto;
    background-color: #010326;
    margin: 50px auto 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 30px;
    box-shadow: -1px 1px 1px 0px #010326;
}

.HelpSelect {
    display: flex;
    flex-direction: row;
    width: 11%;
    justify-content: space-between;
}

.Campo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.EtiquetaCampo {
    font-family: inherit;
    color: #f2f2f2;
    margin: 0px;
    font-size: 20px;
}

.FooterAgregarTrabajador {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    margin-bottom: 50px;
}

.Select {
    font-family: inherit;
    font-weight: bold;
    height: 40px;
    background-color: #e9632f;
    color: #f2f2f2;
    border-radius: 4px;
    border: 0px;
    margin-top: 5px;
}

.Asterisco {
    color: #e9632f;
}