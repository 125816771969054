.Header {
  background-color: #010326;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #f3f3f3;
  z-index: 10;
}

.HeaderPlanillas {
  background-color: white;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
  z-index: 10;
}

.MenuTitulo {
  height: inherit;
  width: 50%;
  display: flex;
  flex-direction: row;
}
 
.SignOut {
  width: 200px;
  height: 50px;
  margin: auto 30px;
  background-color: #fa6320;
  border-radius: 3px;
  border: 0px;
  color: #F2F2F2;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.SignOut:hover {
  background-color: #ca3900;
}

.SignOut:active {
	position:relative;
	top:1px;
}

.Titulo {
  color: #fa6320;
  font-family: 'Noto Sans KR', sans-serif;
  margin: auto 10px;
  font-size: 56px;
  height: 80px;
}

.TituloPlanillas {
  color: black;
  font-family: 'Noto Sans KR', sans-serif;
  margin: auto 10px;
  font-size: 56px;
  height: 80px;
}

.BurgerContainer {
  height:55px;
  display: flex;
  flex-direction: column;
  width: 55px;
  background-color: #010326;
  margin: auto 30px;
  justify-content: center;
  align-items: center;
}

.BurgerContainerPlanillas {
  height:55px;
  display: flex;
  flex-direction: column;
  width: 55px;
  background-color: white;
  margin: auto 30px;
  justify-content: center;
  align-items: center;
}

.BurgerContainer:hover {
  opacity: 0.8;
}

.BurgerContainerPlanillas:hover {
  background-color: #F2F2F2;
}

.Burger {
  height: 4px;
  width: 70%;
  background-color: #fa6320;
  margin: 3px;
}