.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: -webkit-fill-available;
  width: auto;
  background-color: #fafcfc;
}

.Load {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #fafcfc;
  max-width: none;
}