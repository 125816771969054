.Constructores {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  background-color: #fafcfc;
  min-height: -webkit-fill-available;
  padding-bottom: 20px;
}

.Boton {
  width: 200px;
  height: 35px;
  margin: auto 30px;
  background-color: #fa6320;
  border-radius: 3px;
  border:0px solid #ca3900;
  color: #f3f3f3;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin: auto;
  text-decoration:none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow:0px 3px 3px 0px #ca3900;
}

.Boton:hover {
  background-color: #ca3900;
}

.Boton:active {
	position:relative;
	top:1px;
}

.ListaConstructores {
  background-color: #F2F2F2;
  padding-bottom: 20px;
  width: 95%;
  margin: 0 auto;
}

.Constructor {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 2px auto 5px auto;
  height: 35px;
  width: 100%;
  padding: 0px;
  background-color: #282c34;
  color: #f3f3f3;
  justify-content: stretch;
  border-radius: 3px;
  box-shadow:0px 2px 2px 0px #282c34;
  z-index: 5;
}

.EtiquetasTitulos {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 4px auto 4px auto;
  width: 95%;
  height: 50px;
  background-color: #0F1A59;
  color: #F2F2F2;
  justify-content: stretch;
  border-radius: 3px;
  padding: 0 2px;
  box-shadow:0px 2px 2px 0px #0F1A59;
  z-index: 10;
}

.EtiquetaTitulo {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  margin: auto 4px;
  text-align: center;
  font-size: 18px;
}

.Etiqueta {
  font-family: 'Noto Sans KR', sans-serif;
  margin: auto 4px;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
}

.HeaderConstructores {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  height: 40px;
  width: 100%;
  padding: 0px;
  color: #F2F2F2;
  justify-content: space-around;
}

.Help {
  margin: 5px 0 5px 5px;
  height: 15px;
  width: 15px;
  color: #fa6320;
  text-align: center;
  border-radius: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bolder;
}

.Checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
}

.Pencil {
  padding-top: 5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: auto;
}

.Pencil:hover {
  opacity: 0.9;
  cursor: pointer;
}

.Trash {
  padding-top: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin: auto;
}

.Trash:hover {
  opacity: 0.9;
  cursor: pointer;
}