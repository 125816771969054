.RevisionHoras {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
    background-color: #fafcfc;
  }
  
  .Boton {
    width: 150px;
    height: 35px;
    margin: auto 30px;
    background-color: #fa6320;
    border-radius: 3px;
    border:0px solid #ca3900;
    color: #f3f3f3;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin: auto;
    text-decoration:none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow:0px 3px 3px 0px #ca3900;
  }
  
  .Boton:hover {
    background-color: #ca3900;
  }
  
  .Boton:active {
      position:relative;
      top:1px;
  }
  
  .ListaConstructoresRevision {
    background-color: #f3f3f3;
    padding-bottom: 20px;
    width: 90%;
    margin: auto;
  }
  
  .ConstructorRevision {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: 2px auto 5px auto;
    height: 35px;
    width: 100%;
    padding: 0px;
    background-color: #282c34;
    color: #fafcfc;
    justify-content: flex-start;
    border-radius: 3px;
    box-shadow:0px 2px 2px 0px #282c34;
  }
  
  .EtiquetasTitulosRevision {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: 4px auto;
    width: 90%;
    padding: 0px;
    height: 70px;
    background-color: #00124d;
    color: #f3f3f3;
    justify-content: flex-start;
    border-radius: 3px;
    padding: 0 2px;
    box-shadow:0px 2px 2px 0px #00124d;
  }
  
  .EtiquetaTitulo {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: bold;
    margin: auto 4px;
    text-align: center;
    font-size: 18px;
  }
  
  .Etiqueta {
    font-family: 'Noto Sans KR', sans-serif;
    margin: auto 4px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
  }
  
  .HeaderRevision {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    height: 40px;
    width: 100%;
    padding: 0px;
    color: #f3f3f3;
    justify-content: space-around;
  }