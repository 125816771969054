.PlanillaConstruccion {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: auto;
    background-color: white;
    min-height: 42em;
}

.BotonPlanilla {
    width: 150px;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    border:1px solid black;
    color: black;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    text-decoration:none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.BotonPlanilla:hover {
  background-color: lightgrey;
}
  
.BotonPlanilla:active {
  position:relative;
  top:1px;
}
  
.ListaConstructoresPlanillaFinal {
  background-color: white;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
}
 
.ConstructorPlanilla {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: auto 0;
  height: 30px;
  width: 100%;
  padding: 0px;
  background-color: white;
  color: black;
  justify-content: flex-start;
  border:1px solid black;
}

.EtiquetasTitulosPlanilla {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  padding: 0px;
  height: 30px;
  background-color: white;
  color: black;
  justify-content: flex-start;
  border:1px solid black;
}

.EtiquetaTituloPlanilla {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  margin: auto 0;
  text-align: center;
  font-size: 16px;
}

.EtiquetaPlanilla {
  font-family: 'Noto Sans KR', sans-serif;
  margin: auto 0;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.HeaderPlanilla {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  height: 50px;
  width: 50%;
  padding: 10px;
  color: white;
  justify-content: space-around;
}

.Fecha {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.inputFecha {
  height: 30px;
  width: 30px;
  color: black;
  margin: 4px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px;
  border-color: black;
  border-style: inset;
}

.inputFecha:focus {
  border: 0px;
  border-bottom: 1px;
  border-color: black;
  border-style: inset;
}

.EtiquetaFecha {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  margin: auto 0;
  text-align: center;
  font-size: 20px;
}

.Totales {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: auto 0;
  height: 30px;
  width: 100%;
  padding: 0px;
  background-color: white;
  color: black;
  justify-content: space-between;
  border:1px solid black;
}

.Totales-sub {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  height: 30px;
  width: 350px;
  padding-right: 30px;
  background-color: white;
  color: black;
  justify-content: space-between;
}